<template>
<nuxt-layout>
  <article class="error sub-content">
    <div class="error__wrap">
      <header class="error__header">
        <p>{{code}}</p>
        <h1 v-html="labels.title"/>
      </header>
      <p v-html="labels.description" class="error__description"/>
      <nav class="error__nav">
        <ButtonRoundFill @click="onClickHome">메인페이지로 돌아가기</ButtonRoundFill>
      </nav>
    </div>
  </article>
</nuxt-layout>
</template>

<script setup>
import { computed } from 'vue'
import ButtonRoundFill from '~/components/button/round-fill.vue'

const router = useRouter()
const props = defineProps({
  error: {
    url: String,
    statusCode: [ String, Number ],
    statusMessage: String,
    message: String,
    description: String,
  }
})
const code = computed(() => {
  switch (Number(props.error.statusCode))
  {
    case 404:
      return 404
    default:
      return 500
  }
})
const labels = computed(() => {
  switch (code.value)
  {
    case 404:
      return {
        title: `죄송합니다. 요청하신 페이지를 찾을 수 없습니다.`,
        description: `요청하신 페이지가 사라졌거나 주소가 변경되었을 수 있습니다.<br/>입력하신 주소가 정확한지 다시 한번 확인해주시기 바랍니다.`,
      }
    default:
      return {
        title: `죄송합니다. 페이지를 표시할 수 없습니다.`,
        description: `서비스 이용에 불편을 드려 죄송합니다.<br/>시스템에서 오류가 발생해 서비스 접속이 원활하지 않습니다.<br/>새로고침하시거나 잠시 후 다시 이용해주시기 바랍니다.`,
      }
  }
})

// print message for dev
if (process.dev && process.client)
{
  console.group('Service error')
  console.error('Code:', props.error.statusCode)
  console.error('Status:', props.error.statusMessage)
  console.error('Message:', props.error.message)
  console.error('Description:', props.error.description)
  console.groupEnd()
}

function onClickHome()
{
  location.href = '/'
}
</script>

<style lang="scss" scoped>
@use './assets/scss/mixins';
.error {
  &__wrap {
    display: grid;
    place-content: center;
    height: calc(100vh - var(--header-height));
    min-height: 520px;
    box-sizing: border-box;
    text-align: center;
    padding: 0 24px;
  }
  &__header {
    p {
      margin: 0;
      font-weight: 800;
      font-size: 80px;
      line-height: 1.25;
      letter-spacing: -4px;
    }
    h1 {
      margin: 12px 0 0;
      font-size: 22px;
      line-height: 1.42;
    }
  }
  &__description {
    margin: 20px 0 0;
    font-size: 14px;
    color: var(--color-weak);
    font-weight: 600;
    line-height: 1.42;
  }
  &__nav {
    margin: 48px 0 0;
  }
  @include mixins.responsive(tablet) {
    &__wrap {
      padding: 0 60px;
    }
    &__header {
      p {
        font-size: 150px;
        letter-spacing: -8px;
        line-height: 1;
      }
      h1 {
        margin-top: 30px;
        font-size: 36px;
        line-height: 1.25;
      }
    }
    &__description {
      margin-top: 24px;
      font-size: 16px;
      line-height: 1.45;
    }
  }
}
</style>
